// colors 

$cl-white: #fff;
$cl-orange: #F57D60;
$cl-default: #202347;

//Fonts
$font-avenir: 'Avenir Next Cyr';
$font-bebas: 'Bebas Neue';

$font-size-14: 1.4rem;
$font-size-16: 1.6rem;
$font-size-18: 1.8rem;
$font-size-20: 2rem;
$font-size-24: 2.4rem;
$font-size-30: 3rem;
$font-size-40: 4rem;
$font-size-50: 5rem;
$font-size-60: 6rem;
$font-size-72: 7.2rem;
$font-size-90: 9rem;


$line-height-17: 1.7rem;
$line-height-19: 1.9rem;
$line-height-26: 2.6rem;


// fonts
.u-text-14 {
	font-size: $font-size-14;
	line-height: $line-height-17;
}
.u-text-16 {
	font-size: $font-size-16;
	line-height: $line-height-19;
}

.u-text-18 {
	font-size: $font-size-18;
	line-height: $line-height-26;
}

.u-text-24 {
	font-size: $font-size-24;
	line-height: $line-height-26;
}
.u-text-36 {
	font-size: $font-size-72 / 2;
	line-height: $font-size-72 / 2;
}

.u-cl-white {
	color: $cl-white;
}
.u-cl-orange {
	color: $cl-orange;
}

.u-cl-default {
	color: $cl-default;
}

.u-text-left {
	text-align: left;
}
.u-text-center {
	text-align: center;
}
.u-text-right {
	text-align: right;
}

.u-wd-100p {
	width: 100% !important;
}

.u-of-hidden {
  overflow: hidden;
}

.u-of-hidden-x {
  overflow-x: hidden;
}

.u-of-auto-x {
  overflow-x: auto;
}

.u-of-auto-y {
  overflow-y: auto;
}

// headings
.u-heading-90 {
  font-size: $font-size-50;
	font-family: $font-bebas;

  @media only screen and (min-width: $screen-xl) {
    font-size: $font-size-60;
  }

  @media only screen and (min-width: $screen-xxl) {
    font-size: $font-size-90;
  }
}

.u-heading-72 {
  font-size: $font-size-30;
	font-family: $font-bebas;

  @media only screen and (min-width: $screen-md) {
    font-size: $font-size-40;
  }

  @media only screen and (min-width: $screen-xl) {
    font-size: $font-size-72;
  }
}

.u-list-square {
	li {
		position: relative;

		&:before {
			content: '';
			width: .8rem;
			height: .8rem;
			display: block;
			position: absolute;
			left: 0;
			top: 0.6rem;
			background: $cl-default;
		}
	}
}

*,
*::before,
*::after {
	box-sizing: border-box;
}

.flex {
	display: flex;
	align-items: flex-start;
	justify-content: flex-start;
	flex-wrap: wrap;

	&.e--ai-center {
		align-items: center;
	}

	&.e--justify {
		justify-content: space-between;
	}

	&.e--ha-center {
		justify-content: center;
	}
}

html {
	font-size: 10px;
	font-family: $font-avenir;
}

.u-bebas-regular {
	font-family: $font-bebas;
	font-weight: normal;
}

.u-bebas-bold {
	font-family: $font-bebas;
	font-weight: bold;
}

.u-avenir-regular {
	font-family: $font-avenir;
	font-weight: normal;
}

.u-avenir-bold {
	font-family: $font-avenir;
	font-weight: bold;
}

a {
	text-decoration: none;
}


html,
body {
	min-height: 100%;
	position: relative;
}

body {
	font-size: 1rem;
	line-height: 1;
}

ul,
ol,
li,
p,
h1,
h2,
h3,
h4,
h5,
h6 {
	margin: 0;
	padding: 0;
}

h1 {
	font-size: $font-size-90;
}

ul,
ol {
	list-style: none;
}


.btn {
	display: inline-flex;
	position: relative;
	text-align: center;
	font-size: $font-size-16;
	padding: 1.5rem 3rem;
	background-color: $cl-orange;
	font-family: $font-avenir;
	font-weight: bold;
	text-transform: uppercase;
	color: $cl-white;
	flex-shrink: 0;
	z-index: 10;
	transition: all 0.3s ease;

	&:hover {
		opacity: 0.8;
	}

	&-container {
		position: relative;
		overflow: hidden;
		display: flex;
		align-items: center;

		&:before, &:after {
			content: '';
			width: 100%;
			height: 1rem;
			background: $cl-default;
			display: block;
		}
	}
}

.header {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	z-index: 50;

	&.e--bg-dark {
		background: rgba(0,0,0, 0.5);
	}
}


.navi {
	display: none;

	&-list {
		display: flex;
		text-transform: uppercase;
		a {
			color: $cl-white;
		}
	}

	&-divider {
		height: 26px;
		width: 2px;
		background: rgba(255,255,255, 0.5);
	}

	&-toggle {
		right: 0;
		height: 40px;
		width: 40px;
		margin-left: auto;
		cursor: pointer;
		padding: 5px;

		span {
			display: block;
			width: 30px;
			margin: 5px 0;
			height: 3px;
			background: $cl-white;
		}
	}

	@media only screen and (min-width: $screen-xl) {
		display: flex;
		flex-wrap: wrap;
		align-items: center;

		&-toggle {
			display: none;
		}
  }
}

.fs {
	height: 100vh;
	width: 100%;
	background: url(../../img/first-screen.webp) top center no-repeat;
	background-size: cover;
	position: relative;
	overflow-x: hidden;

	&-container {
		height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
		padding-left: 1.5rem;
		padding-right: 1.5rem;
		z-index: 10;
		position: relative;
	}

	.intro {
		font-size: $font-size-24;
	}


	@media only screen and (min-width: $screen-md) {
		overflow-x: unset;

		&-container {
			padding-left: 14rem;
			padding-right: 1.5rem;
		}
  }
}


#svg-triangle-1 {
	position: absolute;
	left: 0;
	bottom: 0;
	height: 100vh;
	width: 90rem;
	z-index: 0;
	opacity: .15;
}

#svg-triangle-2 {
	position: absolute;
	width: 110%;
	height: 180%;
	top: 20%;
	left: -60%;
	z-index: 0;
	opacity: 0.25;
}


.u-lined {
	position: relative;

	&.e--lined-left {
		&:before {
			top: calc(50% - 1rem);
		}
	}

	&.e--lined-center {
		text-align: center;
		&:before {
			width: 100px;
		 top: 100%;
			right: 50%;
			margin-right: -50px;
		}
	}

	&.e--cl-white:before {
		background: $cl-white;
	}

	&.e--cl-default:before {
		background: $cl-default;
	}


	&:before {
		content: '';
		width: 100%;
		display: block;
		position: absolute;
		right: 100%;
		margin-right: 3rem;
		height: 1rem;

	}
}

.section {

	position: relative;
}

.anchor {
	position: absolute;
	top: 0;
	left: 0;
}

.l-container {

	 position: relative;
	 margin: 0 auto;
	 padding-left: 15px;
	 padding-right: 15px;

	 &.c-small {
		 max-width: 980px;
	 }

	 &.c--big {
		max-width: 1200px;
	}
}

.l-flex {
	display: flex;
	flex-direction: column;

	&.e--ai-center {
		align-items: center;
	}



	@media only screen and (min-width: $screen-xl) {
    flex-direction: row;
  }

  @media only screen and (min-width: $screen-xxl) {

  }

}

.content {

	h3 { font-size: $font-size-30; }

  p {
		font-size: $font-size-18;
		line-height: $line-height-26;
		margin-bottom: 3rem;
	}
}


.img-with-label {

	&__icon {
		position: relative;
		margin: 0 auto 1rem;
		width: 15rem;
		height: 15rem;
		display: flex;
    align-items: center;
		justify-content: center;


		&.e--small {
			width: 10rem;
			height: 10rem;
		}

		&-shape {
			display: block;
			position: absolute;
			left: 0;
			top: 0;
			width: 110%;
			height: 110%;
			border-radius: 50%;
			background: $cl-white;
			box-shadow: 0px 13px 29px rgba(92, 93, 149, 0.32);
			margin-left: -5%;
			margin-top: -5%;

		&.e--bg:before {
			content: '';
    background: url(../../img/icon-bg.svg) 0 0/100% no-repeat;
    position: absolute;
    width: 90%;
    height: 90%;
    top: 5%;
    left: 5%;
    z-index: -1;
		}
	}

		img {
			max-width: 100%;
			height: auto; 
			z-index: 2;
			padding-left: 1.5rem;
			padding-right: 1.5rem;
		}
	}
	p {
		width: 24rem;
		margin: 0 auto;
	}
}
// clients 

.clients {
	background: url(../../img/clients-bg.webp) bottom center no-repeat;
	z-index: 10;
  position: relative;

	&-box {
		display: flex;
		flex-wrap: wrap;
 
		&__item {
		align-self: center;
		width: 50%;
		padding: 1rem;

		img {
			max-width: 100%;
		}
	}
}

	@media only screen and (min-width: $screen-xl) {
		background: url(../../img/clients-bg.webp) top center no-repeat;
		background-size: cover;

		&-box__item { width: calc(100% / 4); }
  }

	@media only screen and (min-width: $screen-xxl) {
		&-box__item { width: calc(100% / 6); }
  }
}


.e--33 {
	width: 100%;

	@media only screen and (min-width: $screen-md) {
			width: calc(100% / 2);
	}

	@media only screen and (min-width: $screen-xl) {
		 width: calc(100% / 3);
	}
}

.e--50 {
	width: 100%;

	@media only screen and (min-width: $screen-xl) {
			width: calc(100% / 2);
	}

}


// services

.services {
z-index: 10;
 margin-bottom: -10rem;

	&-list {
		display: flex;
		flex-wrap: wrap;
	}

	&-item {

		padding: 1rem;

		&__inner {
			background: $cl-white;
			box-shadow: 0px 12px 38px rgba(0, 0, 0, 0.1);
			padding: 2rem;
			height: 100%;
		}

		h3 {
			font-size: 3.3rem;
			margin-bottom: 1rem;
		}

		li {
			font-size: 1.4rem;
			line-height: 2.1rem;
			margin-bottom: 1.5rem;
			position: relative;
			padding-left: 1.5rem;
		}

		@media only screen and (min-width: $screen-xl) {
		  h3 {
				font-size: 4.3rem;
			}

		}

	}

	@media only screen and (min-width: $screen-xl) {
		margin-bottom: -30rem;
	}
}

// principles
.principles {
	background: url(../../img/principles-bg.png) top center no-repeat;
	background-size: cover;

	&-list {
		display: flex;
		flex-wrap: wrap;
	}

	&-item {
		padding: 1rem;

		&__inner {
			height: 100%;
			border: 2px solid rgba(255,255,255, 0.5);
		}
	}
}
.a-link {
	display: inline-flex;
	border-bottom: 4px solid $cl-orange;
	transition: all 0.3s ease;

	&:hover {
		border-color: $cl-white;
		color: #F57D60;
	}
}

// cases 
.cases {
 &-list {
	 display: flex;
	 flex-wrap: wrap;

 }

 &-item {
	  padding: 1rem;

		&__inner {
			display: flex;
			flex-direction: column;
			justify-content: flex-end;
			height: 100%;
			padding: 10rem 2rem 2rem;

			h3 {
				 font-size: 4.8rem;
			}
		}
 }
}


// footer 

.footer {
	background-color: $cl-default;
	padding: 3.5rem 0;

	&-inner {
		display: flex;
		flex-direction: column;
		align-items: center;
	}
	@media only screen and (min-width: $screen-xl) {
		 &-inner {
			 flex-direction: row;
			 justify-content: space-between;
		 }
	}
}


// social

.social {
	display: flex;

	&-icon {
		width: 4.5rem;
		height: 4.5rem;
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 2rem;
		border: 1px solid $cl-white;
		color: $cl-white;
		transition: all 0.3s ease;

		&:hover {
			color: $cl-orange;
			border-color: $cl-orange;
		}

	}
}

.triangle {
	 position: absolute;
	 left: 50%;
	 top: 0;
	 bottom: 0;
	 right: 0;
	 opacity: 0.3;

	 .triangle-1 {
		background: url(../../img/triangle.svg) 0 0/cover no-repeat;
    width: 160%;
    height: 85%;
    display: block;
    bottom: 0;
    position: absolute;
    left: -50%;
	 }

	 .triangle-2 {
		background: url(../../img/triangle.svg) 0 0/cover no-repeat;
    width: 160%;
    height: 100%;
    display: block;
    bottom: 0;
    position: absolute;
    left: 0;
	 }
}


.copyright {

	a {
		text-decoration: underline;
		transition: all 0.3s ease;

		&:hover {
			color: $cl-orange;
		}
	}
}

// popup
.popup {
	background: $cl-white;
	border-radius: 2rem;
	max-width: 90rem;
}