//Sizing
$spacing: 1rem;

$spacing-map: (
  '0': 0,
  '05': ($spacing * .5),    // 5
  '1': $spacing,            // 10
  '2': ($spacing * 2),      // 20
  '3': ($spacing * 3),      // 30
  '4': ($spacing * 4),      // 40
  '5': ($spacing * 5),      // 50
  '8': ($spacing * 8),      // 80
  '10': ($spacing * 10),    // 100
  '15': ($spacing * 15),    // 150
  'auto': auto
);

$spacing-05: map-get($spacing-map, '05');
$spacing-1: map-get($spacing-map, '1');
$spacing-2: map-get($spacing-map, '2');
$spacing-4: map-get($spacing-map, '4');
$spacing-5: map-get($spacing-map, '5');
$spacing-8: map-get($spacing-map, '8');
$spacing-10: map-get($spacing-map, '10');
$spacing-15: map-get($spacing-map, '15');


//Mediaquery
$min-screen-width: 360px;
$screen-sm: 480px;
$screen-md: 760px;
$screen-lg: 950px;
$screen-xl: 1025px;
$screen-xxl: 1281px;

$media-map: (
  sm: $screen-sm,
  md: $screen-md,
  lg: $screen-lg,
  xl: $screen-xl,
  xxl: $screen-xxl
);

/*
  $m-type - padding or margin.
  $m-direction - direction spacing.
  $m-size - size.
  $m-breakpoint - breakpoint.
  Example - '.u-pd-b5', '.u-pd-b5@lg'
*/

$spacing-directions: (
  't': 'top',
  'r': 'right',
  'b': 'bottom',
  'l': 'left',
  'va': ('top', 'bottom'),
  'ha': ('left', 'right')
);

$spacing-type: (
  pd: padding,
  mr: margin
);

@mixin mix-spacing($m-type, $m-direction, $m-size, $m-breakpoint: null) {
  @include mix-spacing-repeater($m-type, $m-direction, $m-size, $rule-mq: '');
  @if $m-breakpoint {
    @each $name-mq, $value-mq in $m-breakpoint {
      $rule-mq: \@#{$name-mq};

      @media (min-width: $value-mq) {
        @include mix-spacing-repeater($m-type, $m-direction, $m-size, $rule-mq);
      }
    }
  }
}

@mixin mix-spacing-repeater($m-type, $m-direction, $m-size, $rule-mq: '') {
  @each $name-type, $value-type in $m-type {
    @each $name-size, $value-size in $m-size {
      @each $name-direction, $value-direction in $m-direction {
        $rule: '.u-#{$name-type}-#{$name-direction}#{$name-size}#{$rule-mq}';

        @if type-of($value-direction) == string {
          #{$rule} {#{$value-type}-#{$value-direction}: $value-size !important}
        } @else if type-of($value-direction) == list {
          #{$rule} {
            @each $value-direction-list in $value-direction {
              #{$value-type}-#{$value-direction-list}: $value-size !important
            }
          }
        }
      }
    }
  }
}

@include mix-spacing($spacing-type, $spacing-directions, $spacing-map, $media-map);


/*
  $m-type - padding or margin.
  $m-direction - direction spacing.
  $spacing-mq-size - responsive map spacing.
  $m-mq - breakpoint.
  Example:
    variables:
    $query-map: (
      lg: 1280px
    );
    $spacing-directions: (
      't': 'top'
    );
    $spacing-type: (
      'pd': 'padding'
    );
    $spacing-mq-size: (
      '3': (
        'default': '2rem',
        'lg': '3rem'
      ),
    );
    css:
    .u-mq-pd-t3 {
      padding-top: 2rem !important;
    }
    @media (min-width: 1280px) {
      .u-mq-pd-t3 {
        padding-top: 3rem !important;
      }
    }
*/

$spacing-directions: (
  't': 'top',
  'r': 'right',
  'b': 'bottom',
  'l': 'left',
  'va': ('top', 'bottom'),
  'ha': ('left', 'right')
);

$spacing-mq-size: (
  '3': (
    'default': 2rem,
    'lg': 3rem
  ),
  '5': (
    'default': 3rem,
    'lg': 5rem
  ),
  '10': (
    'default': 3rem,
    'md': 5rem,
    'lg': 10rem
  ),
  '15': (
    'default': 5rem,
    'md': 10rem,
    'lg': 15rem
  )
);
@mixin mix-spacing-mq($m-type, $m-direction, $m-mq-size, $m-mq) {
  @each $name-type, $value-type in $m-type {
    @each $name-direction, $value-direction in $m-direction {
      @each $name-mq-size, $value-mq-size in $m-mq-size {
        @if type-of($value-mq-size) == map {
          @each $name-mq-in-size, $value-mq-in-size in $value-mq-size {
            @if map-has-key($m-mq, $name-mq-in-size) {
              @media (min-width: map-get($m-mq, $name-mq-in-size)) {
                @include mix-spacing-mq-repeater($name-type, $value-type, $name-direction, $value-direction, $name-mq-size, $value-mq-in-size);
              }
            }
            @if ($name-mq-in-size == 'default') {
              @include mix-spacing-mq-repeater($name-type, $value-type, $name-direction, $value-direction, $name-mq-size, $value-mq-in-size);
            }
          }
        }
      }
    }
  }
}
@mixin mix-spacing-mq-repeater($n-type, $v-type, $n-direction, $v-direction, $n-eq-size, $v-mq-in-size) {
  $rule: '.u-mq-#{$n-type}-#{$n-direction}#{$n-eq-size}';
  @if type-of($v-direction) == string {
    #{$rule} {#{$v-type}-#{$v-direction}: #{$v-mq-in-size} !important}
  } @else if type-of($v-direction) == list {
    #{$rule} {
      @each $value-direction-list in $v-direction {
        #{$v-type}-#{$value-direction-list}: #{$v-mq-in-size} !important
      }
    }
  }
}
@include mix-spacing-mq($spacing-type, $spacing-directions, $spacing-mq-size, $media-map);


// u-pos
$position-directions-map: (
  t: top,
  r: right,
  b: bottom,
  l: left
);

$position-map: (
  1: $spacing-1,
);

$position-addition-map: (
  rel: relative,
  abs: absolute
);

@mixin mix-position($m-size, $m-direction, $m-additional, $m-breakpoint: null) {
  @include mix-position-repeater($m-size, $m-direction, $m-additional);

  @if $m-breakpoint {
    @each $n-breakpoint, $v-breakpoint in $m-breakpoint {
      $rule-mq: #{$n-breakpoint}\:;

      @media (min-width: $v-breakpoint) {
        @include mix-position-repeater($m-size, $m-direction, $m-additional, $rule-mq);
      }
    }
  }
}

@mixin mix-position-repeater($m-size, $m-direction, $m-additional, $rule-mq:"") {
  @each $n-add, $v-add in $m-additional {
    $rule: ".#{$rule-mq}u-ps-#{$n-add}";
    #{$rule} {position: $v-add}
  }
  @each $n-dir, $v-dir in $m-direction {
    @each $n-size, $v-size in $m-size {
      $rule: ".#{$rule-mq}u-ps-#{$n-dir}#{$n-size}";

      #{$rule} {#{$v-dir}: $v-size}

      @if $n-size != 0 {
        $rule: ".#{$rule-mq}u-ps-#{$n-dir}n#{$n-size}";

        #{$rule} {#{$v-dir}: -$v-size}
      }
    }
  }
}

@include mix-position($position-map, $position-directions-map, $position-addition-map, $media-map);
