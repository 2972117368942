@font-face {
	font-family: 'Avenir Next Cyr';
	src: url('../../fonts/AvenirNextCyr/AvenirNextCyr-Light.eot');
	src: local('Avenir Next Cyr Light'), local('AvenirNextCyr-Light'),
		url('../../fonts/AvenirNextCyr/AvenirNextCyr-Light.eot?#iefix') format('embedded-opentype'),
		url('../../fonts/AvenirNextCyr/AvenirNextCyr-Light.woff') format('woff'),
		url('../../fonts/AvenirNextCyr/AvenirNextCyr-Light.ttf') format('truetype');
	font-weight: 300;
	font-style: normal;
}


@font-face {
	font-family: 'Avenir Next Cyr';
	src: url('../../fonts/AvenirNextCyr/AvenirNextCyr-Demi.eot');
	src: local('Avenir Next Cyr Demi'), local('AvenirNextCyr-Demi'),
		url('../../fonts/AvenirNextCyr/@@/fonts/AvenirNextCyr/AvenirNextCyr-Demi.eot?#iefix') format('embedded-opentype'),
    url('../../fonts/AvenirNextCyr/@@/fonts/AvenirNextCyr/AvenirNextCyr-Demi.woff') format('woff'),
		url('../../fonts/AvenirNextCyr/@@/fonts/AvenirNextCyr/AvenirNextCyr-Demi.ttf') format('truetype');
	font-weight: bold;
	font-style: normal;
}

@font-face {
	font-family: 'Avenir Next Cyr';
	src: url('../../fonts/AvenirNextCyr/AvenirNextCyr-Medium.eot');
	src: local('Avenir Next Cyr Medium'), local('AvenirNextCyr-Medium'),
		url('../../fonts/AvenirNextCyr/AvenirNextCyr-Medium.eot?#iefix') format('embedded-opentype'),
		url('../../fonts/AvenirNextCyr/AvenirNextCyr-Medium.woff') format('woff'),
		url('../../fonts/AvenirNextCyr/AvenirNextCyr-Medium.ttf') format('truetype');
	font-weight: 500;
	font-style: normal;
}

@font-face {
	font-family: 'Avenir Next Cyr';
	src: url('../../fonts/AvenirNextCyr/AvenirNextCyr-Regular.eot');
	src: local('Avenir Next Cyr Regular'), local('AvenirNextCyr-Regular'),
		url('../../fonts/AvenirNextCyr/AvenirNextCyr-Regular.eot?#iefix') format('embedded-opentype'),
		url('../../fonts/AvenirNextCyr/AvenirNextCyr-Regular.woff') format('woff'),
		url('../../fonts/AvenirNextCyr/AvenirNextCyr-Regular.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Avenir Next Cyr Ultra';
	src: url('../../fonts/AvenirNextCyr/AvenirNextCyr-UltraLight.eot');
	src: local('Avenir Next Cyr Ultra Light'), local('AvenirNextCyr-UltraLight'),
		url('../../fonts/AvenirNextCyr/AvenirNextCyr-UltraLight.eot?#iefix') format('embedded-opentype'),
		url('../../fonts/AvenirNextCyr/AvenirNextCyr-UltraLight.woff') format('woff'),
		url('../../fonts/AvenirNextCyr/AvenirNextCyr-UltraLight.ttf') format('truetype');
	font-weight: 200;
	font-style: normal;
}

@font-face {
	font-family: 'Avenir Next Cyr';
	src: url('../../fonts/AvenirNextCyr/AvenirNextCyr-Heavy.eot');
	src: local('Avenir Next Cyr Heavy'), local('AvenirNextCyr-Heavy'),
		url('../../fonts/AvenirNextCyr/AvenirNextCyr-Heavy.eot?#iefix') format('embedded-opentype'),
		url('../../fonts/AvenirNextCyr/AvenirNextCyr-Heavy.woff') format('woff'),
		url('../../fonts/AvenirNextCyr/AvenirNextCyr-Heavy.ttf') format('truetype');
	font-weight: 900;
	font-style: normal;
}

@font-face {
	font-family: 'Avenir Next Cyr';
	src: url('../../fonts/AvenirNextCyr/AvenirNextCyr-Bold.eot');
	src: local('Avenir Next Cyr Bold'), local('AvenirNextCyr-Bold'),
		url('../../fonts/AvenirNextCyr/AvenirNextCyr-Bold.eot?#iefix') format('embedded-opentype'),
		url('../../fonts/AvenirNextCyr/AvenirNextCyr-Bold.woff') format('woff'),
		url('../../fonts/AvenirNextCyr/AvenirNextCyr-Bold.ttf') format('truetype');
	font-weight: bold;
	font-style: normal;
}

@font-face {
	font-family: 'Avenir Next Cyr';
	src: url('../../fonts/AvenirNextCyr/AvenirNextCyr-Thin.eot');
	src: local('Avenir Next Cyr Thin'), local('AvenirNextCyr-Thin'),
		url('../../fonts/AvenirNextCyr/AvenirNextCyr-Thin.eot?#iefix') format('embedded-opentype'),
		url('../../fonts/AvenirNextCyr/AvenirNextCyr-Thin.woff') format('woff'),
		url('../../fonts/AvenirNextCyr/AvenirNextCyr-Thin.ttf') format('truetype');
	font-weight: 100;
	font-style: normal;
}


// Bebas Neue

@font-face {
	font-family: 'Bebas Neue';
	src: url('../../fonts/BebasNeue/BebasNeueRegular.eot');
	src: local('Bebas Neue Regular'), local('BebasNeueRegular'),
	url('../../fonts/BebasNeue/BebasNeueRegular.eot?#iefix') format('embedded-opentype'),
	url('../../fonts/BebasNeue/BebasNeueRegular.woff') format('woff'),
	url('../../fonts/BebasNeue/BebasNeueRegular.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Bebas Neue';
	src: url('../../fonts/BebasNeue/BebasNeueBold.eot');
	src: local('Bebas Neue Bold'), local('BebasNeueBold'),
	url('../../fonts/BebasNeue/BebasNeueBold.eot?#iefix') format('embedded-opentype'),
	url('../../fonts/BebasNeue/BebasNeueBold.woff') format('woff'),
	url('../../fonts/BebasNeue/BebasNeueBold.ttf') format('truetype');
	font-weight: bold;
	font-style: normal;
}